import { useEffect } from 'react';
import Header from '../layout/Header'
import { Link } from 'react-router-dom'
import Footer from '../layout/Footer'

export default function About() {
    useEffect(() => {
        window.scrollTo(0, 0);
        
        }, [])
    return (
        <div>
            <Header />

            <div className="main-content">
                <div className="page-content">
                    {/* Start home */}
                    <section className="page-title-box">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-6">
                                    <div className="text-center text-white">
                                        <h3 className="mb-4">About Us</h3>
                                        <div className="page-next">
                                            <nav
                                                className="d-inline-block"
                                                aria-label="breadcrumb text-center"
                                            >
                                                <ol className="breadcrumb justify-content-center">
                                                    <li className="breadcrumb-item">
                                                        <a href="index.html">Home</a>
                                                    </li>
                                                    
                                                    <li className="breadcrumb-item active" aria-current="page">
                                                        {" "}
                                                        About Us{" "}
                                                    </li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                {/*end col*/}
                            </div>
                            {/*end row*/}
                        </div>
                        {/*end container*/}
                    </section>
                    {/* end home */}
                    {/* START SHAPE */}
                    <div className="position-relative" style={{ zIndex: 1 }}>
                        <div className="shape">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 250">
                                <path
                                    fill="#FFFFFF"
                                    fillOpacity={1}
                                    d="M0,192L120,202.7C240,213,480,235,720,234.7C960,235,1200,213,1320,202.7L1440,192L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                                />
                            </svg>
                        </div>
                    </div>
                    {/* END SHAPE */}
                    {/* START ABOUT */}
                    <section className=" overflow-hidden" style={{marginBottom:"20px"}}>
                        <div className="container">
                            <div className="row align-items-center g-0">
                                <div className="col-lg-6">
                                    <div className="section-title me-lg-5 mt-5">
                                        {/* <h6 className="sub-title">About Us</h6> */}
                                        <h2 className="title mb-4">
                                            Mission:
                                        </h2>
                                        <p >
                                            At Leungo Resources, we're on a mission to make job searching simple and propel career growth in South Africa. We're committed to connecting job seekers and employers through our user-friendly platform, personalized career advice, valuable industry insights, and as a dedicated job-sharing platform.

                                        </p>
                                    
                                       
                                    </div>
                                </div>
                                {/*end col*/}
                                <div className="col-lg-6">
                                    <div className="section-title me-lg-5">
                                     
                                        <h2 className="title mb-4">
                                        Vision:
                                        </h2>
                                        <p className="">
                                        We aim to become a trusted online resource for job seekers, offering more than just job listings – we aim to support comprehensive career growth and development.


                                        </p>
                                    
                                       
                                    </div>
                                </div>
                                {/*end col*/}
                            </div>
                            {/*end row*/}
                        </div>
                        {/*end container*/}
                    </section>
                    {/* END ABOUT */}

                    {/* COMPANY TESTIMONIAL START */}
                    <section className="" style={{marginBottom:"20px"}}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="section-title mb-5 mb-lg-0">
                                        <h4 className="title mb-4">About Us:</h4>
                                        <p className="">
                                            Leungo Resources is transforming the job search experience through a simple online platform. As a dedicated job-sharing platform, we provide clear job descriptions, personalized career guidance, industry insights, interactive skill development resources, and transparent company culture information.
                                        </p>
                                      
                                        <div className="d-flex align-items-center justify-content-between mt-4">
                                          
                                            <ul className="list-inline about-social-menu mb-0">
                                                <li className="list-inline-item">
                                                    <Link to="" className="">
                                                        <i className="uil uil-facebook-f" />
                                                    </Link>
                                                </li>
                                                <li className="list-inline-item">
                                                    <Link to="" className="">
                                                        <i className="uil uil-twitter" />
                                                    </Link>
                                                </li>
                                                <li className="list-inline-item">
                                                    <Link to="" className="">
                                                        <i className="uil uil-whatsapp" />
                                                    </Link>
                                                </li>
                                                <li className="list-inline-item">
                                                    <Link to="" className="">
                                                        <i className="uil uil-instagram" />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/*end col*/}
                                <div className="col-lg-6">
                                
                                <h4 className="title mb-4">Values:</h4>
                                        <ul className="list-unstyled about-list mt-4">

                                            <li> <strong>Accessibility:</strong> Making job opportunities accessible to everyone.
                                            </li>
                                            <li><strong>Simplicity:</strong> Streamlining the job search process for ease of use.
                                            </li>
                                            <li><strong>Community:</strong> Fostering a supportive online community for job seekers.
                                                .</li>
                                            <li><strong>Empowerment:</strong> Empowering individuals to take control of their careers.
                                            </li>
                                        </ul>
                                
                                    
                                    {/*end row*/}
                                </div>
                                {/*end col*/}
                            </div>
                            {/*end row*/}
                        </div>
                        {/*end container*/}
                    </section>
                    {/* COMPANY TESTIMONIAL START */}
                </div>
                {/* End Page-content */}
         


            </div>
<Footer/>
        </div>
    )
}
