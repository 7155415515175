import React, { useEffect } from 'react'
import Header2 from '../layout/Header2'
import { Link, useParams  } from 'react-router-dom'

export default function Jobdetail() {
  const { data } = useParams();
  
  const v = JSON.parse(decodeURIComponent(data));
 

  const cleanText = (htmlString) => {
    const regex = /(<([^>]+)>)/gi;
    return htmlString.replace(regex, "");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  
  }, []);


 


  return (
    <div>
      <Header2 />
      <div className="main-content">

        <div className="page-content">
          <section className="page-title-box">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="text-center text-white">
                    <h3 className="mb-4">Job Details</h3>
                    <div className="page-next">
                      <nav className="d-inline-block" aria-label="breadcrumb text-center">
                        <ol className="breadcrumb justify-content-center">
                          <li className="breadcrumb-item">
                            <Link to="index.html">Home</Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to="">Pages</Link>
                          </li>
                          <li className="breadcrumb-item active" aria-current="page">
                            {" "}
                            Job Details{" "}
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
                {/*end col*/}
              </div>
              {/*end row*/}
            </div>
            {/*end container*/}
          </section>

          <div className="position-relative" style={{ zIndex: 1 }}>
            <div className="shape">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 250">
                <path
                  fill="#FFFFFF"
                  fillOpacity={1}
                  d="M0,192L120,202.7C240,213,480,235,720,234.7C960,235,1200,213,1320,202.7L1440,192L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                />
              </svg>
            </div>
          </div>

          <section className="section">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="card job-detail overflow-hidden">
                    <div>
                      <img
                        src="assets/images/job-detail.jpg"
                        alt=""
                        className="img-fluid"
                      />
                      <div className="job-details-compnay-profile">
                        <img
                          src="assets/images/featured-job/img-10.png"
                          alt=""
                          className="img-fluid rounded-3 rounded-3"
                        />
                      </div>
                    </div>
                    <div className="card-body p-4">
                      <div>
                        <div className="row">
                          <div className="col-md-8">
                            <h5 className="mb-1">{v.job_title}</h5>

                          </div>
                          {/*end col*/}
                          <div className="col-lg-4">
                            <ul className="list-inline mb-0 text-lg-end mt-3 mt-lg-0">
                              <li className="list-inline-item">
                                <div className="favorite-icon">
                                  <Link to="">
                                    <i className="uil uil-heart-alt" >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M20.16 4.61A6.27 6.27 0 0 0 12 4a6.27 6.27 0 0 0-8.16 9.48l7.45 7.45a1 1 0 0 0 1.42 0l7.45-7.45a6.27 6.27 0 0 0 0-8.87m-1.41 7.46L12 18.81l-6.75-6.74a4.28 4.28 0 0 1 3-7.3a4.25 4.25 0 0 1 3 1.25a1 1 0 0 0 1.42 0a4.27 4.27 0 0 1 6 6.05Z" /></svg>
                                    </i>
                                  </Link>
                                </div>
                              </li>
                              <li className="list-inline-item">
                                <div className="favorite-icon">
                                  <Link to="">
                                    <i className="uil uil-setting" >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M19.9 12.66a1 1 0 0 1 0-1.32l1.28-1.44a1 1 0 0 0 .12-1.17l-2-3.46a1 1 0 0 0-1.07-.48l-1.88.38a1 1 0 0 1-1.15-.66l-.61-1.83a1 1 0 0 0-.95-.68h-4a1 1 0 0 0-1 .68l-.56 1.83a1 1 0 0 1-1.15.66L5 4.79a1 1 0 0 0-1 .48L2 8.73a1 1 0 0 0 .1 1.17l1.27 1.44a1 1 0 0 1 0 1.32L2.1 14.1a1 1 0 0 0-.1 1.17l2 3.46a1 1 0 0 0 1.07.48l1.88-.38a1 1 0 0 1 1.15.66l.61 1.83a1 1 0 0 0 1 .68h4a1 1 0 0 0 .95-.68l.61-1.83a1 1 0 0 1 1.15-.66l1.88.38a1 1 0 0 0 1.07-.48l2-3.46a1 1 0 0 0-.12-1.17ZM18.41 14l.8.9l-1.28 2.22l-1.18-.24a3 3 0 0 0-3.45 2L12.92 20h-2.56L10 18.86a3 3 0 0 0-3.45-2l-1.18.24l-1.3-2.21l.8-.9a3 3 0 0 0 0-4l-.8-.9l1.28-2.2l1.18.24a3 3 0 0 0 3.45-2L10.36 4h2.56l.38 1.14a3 3 0 0 0 3.45 2l1.18-.24l1.28 2.22l-.8.9a3 3 0 0 0 0 3.98m-6.77-6a4 4 0 1 0 4 4a4 4 0 0 0-4-4m0 6a2 2 0 1 1 2-2a2 2 0 0 1-2 2" /></svg>
                                    </i>
                                  </Link>
                                </div>
                              </li>
                            </ul>
                          </div>
                          {/*end col*/}
                        </div>
                        {/*end row*/}
                      </div>
                      <div className="mt-4">
                        <div className="row g-2">
                         
                          <div className="col-lg-3">
                            <div className="border p-3">
                              <p className="text-muted fs-13 mb-0">Employee type</p>
                              <p className="fw-medium mb-0">{v.job_type}</p>
                            </div>
                          </div>
                        
                          
                        </div>
                      </div>
                      {/*end Experience*/}
                      <div className="mt-4">
                        <h5 className="mb-3">Job Description</h5>
                        <div className="job-detail-desc">
                          <p className="text-muted mb-0">
                            <div>{cleanText(v.job_description)}</div>
                          </p>
                        </div>
                      </div>

                    </div>
                    {/*end card-body*/}
                  </div>
                  {/*end job-detail*/}


                </div>
                {/*end col*/}
                <div className="col-lg-4 mt-4 mt-lg-0">
                  {/*start side-bar*/}
                  <div className="side-bar ms-lg-4">
                    <div className="card job-overview">
                      <div className="card-body p-4">
                        <h6 className="fs-17">Job Overview</h6>
                        <ul className="list-unstyled mt-4 mb-0">
                          <li>
                            <div className="d-flex mt-4">
                              <i className=" bg-soft-primary icon" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M15.71 12.71a6 6 0 1 0-7.42 0a10 10 0 0 0-6.22 8.18a1 1 0 0 0 2 .22a8 8 0 0 1 15.9 0a1 1 0 0 0 1 .89h.11a1 1 0 0 0 .88-1.1a10 10 0 0 0-6.25-8.19M12 12a4 4 0 1 1 4-4a4 4 0 0 1-4 4" /></svg>
                              </i>
                              <div className="ms-3">
                                <h6 className="fs-14 mb-2">Job Title</h6>
                                <p className="text-muted mb-0">{v.job_title}</p>
                              </div>
                            </div>
                          </li>

                          <li>
                            <div className="d-flex mt-4">
                              <i className=" icon bg-soft-primary" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M18 4.48a8.45 8.45 0 0 0-12 12l5.27 5.28a1 1 0 0 0 1.42 0L18 16.43a8.45 8.45 0 0 0 0-11.95M16.57 15L12 19.59L7.43 15a6.46 6.46 0 1 1 9.14 0M9 7.41a4.32 4.32 0 0 0 0 6.1a4.31 4.31 0 0 0 7.36-3a4.24 4.24 0 0 0-1.26-3.05A4.3 4.3 0 0 0 9 7.41m4.69 4.68a2.33 2.33 0 1 1 .67-1.63a2.33 2.33 0 0 1-.72 1.63Z" /></svg>
                              </i>
                              <div className="ms-3">
                                <h6 className="fs-14 mb-2">Location</h6>
                                <p className="text-muted mb-0">{v.company_location}</p>
                              </div>
                            </div>
                          </li>


                          <li>
                            <div className="d-flex mt-4">
                              <i className=" icon bg-soft-primary" >

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M14 8h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2m0 4h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2M9 8h1a1 1 0 0 0 0-2H9a1 1 0 0 0 0 2m0 4h1a1 1 0 0 0 0-2H9a1 1 0 0 0 0 2m12 8h-1V3a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v17H3a1 1 0 0 0 0 2h18a1 1 0 0 0 0-2m-8 0h-2v-4h2Zm5 0h-3v-5a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v5H6V4h12Z" /></svg>
                              </i>
                              <div className="ms-3">
                                <h6 className="fs-14 mb-2">Industry</h6>
                                <p className="text-muted mb-0">{v.company_name}</p>
                              </div>
                            </div>
                          </li>

                        </ul>
                        <div className="mt-3">
                          <Link
                             to={v.link}
                          
                            className="btn btn-primary btn-hover w-100 mt-2"
                            // onClick={() => handleDownloadClick(v.pdf_link)}

                          >
                            Apply Now <i className="uil uil-arrow-right" />
                          </Link>
                         
                        </div>
                      </div>
                      {/*end card-body*/}
                    </div>
                    {/*end job-overview*/}


                  </div>
                  {/*end side-bar*/}
                </div>
                {/*end col*/}
              </div>
              {/*end row*/}
            </div>
            {/*end container*/}
          </section>



        </div>
      </div>


    </div>
  )
}
