import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import Hom from "../components/Home"
export default function Home() {
  return (
    <div>
      <Header />
      <Hom />
      <Footer />


    </div>
  )
}
