import './App.css';

import { Routes,Route,BrowserRouter} from 'react-router-dom'
// import ProtectedRoute from './utils/ProtectedRoute';
// import Login from './auth/Login';
// import Signup from './auth/Signup';
// import Forgerpass from './auth/Forgerpass';
// import Verification from './auth/Verification';
// import Confimpassword from './auth/Confimpassword';
import Jobs from './pages/Jobs';
import Description from './pages/Description';
import Contact from './components/Contact';
import Home from './pages/Home';
import About from './components/About';
import Career from './components/Career';




function App() {
 

  return (
   
    <BrowserRouter>

    <Routes>
      <Route path='/' element={ <Home/> }/>
      <Route path='/jobs' element={ <Jobs/> }/>
      <Route path='/description/:data' element={ <Description/> }/>
      <Route path='/contact' element={ <Contact/> }/>
      <Route path='/about' element={ <About/> }/>
      <Route path='/careerresources' element={ <Career/> }/>

      {/* <Route path='/jobs' element={<ProtectedRoute Component={Jobs} />} />
      <Route path='/description/:data' element={<Description />} />
      <Route path='/contact' element={<ProtectedRoute Component={Contact} />} />
      <Route path='/about' element={<ProtectedRoute Component={About} />} />
      <Route path='/careerresources' element={<ProtectedRoute Component={Career} />} /> */}

      {/* <Route path='/' element={<Login/>}/>
      <Route path='/signup' element={<Signup/>}/>
      <Route path='/forgetpassword' element={<Forgerpass/>}/>
      <Route path='/verification' element={<Verification/>}/>
      <Route path='/confimpassword' element={<Confimpassword/>}/> */}
    </Routes>
    </BrowserRouter>
  );
}

export default App;
