import React, { useEffect, useState } from 'react'
import Header from '../layout/Header2'
import Footer from '../layout/Footer'
import { FaStar, FaAngleDoubleRight } from "react-icons/fa";
import { Link, useLocation } from 'react-router-dom';
import { BaseUrl } from '../baseurl/BaseUrl';
import { toast } from "react-toastify";
import axios from 'axios';
import jobicon from "../images/jobicon.png"
import Loader from './Loader';


export default function Jobs() {
  const [loader, setLoader] = useState(false)
  const [category, setCategory] = useState([])
  const [jobs, setJobs] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  // const [linkedindata, setLinkedindata] = useState([])
  // const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [locations, setlocations] = useState("");
  // const [prev, setPrev] = useState(null);
  // const [next, setNext] = useState(null);

  const location = useLocation()
  // console.log("location", location)
  // console.log("next ", next)
  // console.log("prev ", prev)



  const JobsApi = () => {
    setLoader(true)

    var requestOptions = {
      method: "get",
      redirect: "follow",
    };
    axios(`${BaseUrl.baseUrl}gov_za/retrieve_data`, requestOptions).then((result) => {
      if (result?.data?.status) {
        setLoader(false)
        const uniqueCategories = Array.from(new Set(result?.data?.category));
        setCategory(uniqueCategories);
        const uniqueJobs = Array.from(new Set(result?.data?.data.map(job => job.title)))
          .map(title => result?.data?.data.find(job => job.title === title));

        setJobs(uniqueJobs);
      } else {
        setLoader(false)
        toast.error(result.essage, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    })
      .catch((error) => {
        setLoader(false)
        toast.error(error.essage, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  // Linkedin 
  // const JobsLinkedin = (url) => {
  //   console.log("url",url)
  //   setLoader(true)

  //   var requestOptions = {
  //     method: "get",
  //     redirect: "follow",
  //   };
  //   axios(url,requestOptions)
  //     axios(`${BaseUrl.baseUrl}indeed_za/retrieve_data/?page=1`, requestOptions)
  //     .then((results) => {
  //       if (results.status === 200) {
  //         setLoader(false)
  //         console.log("Linkedin", results.data)
  //         setPrev(results.data.previous)
  //         setNext(results.data.next)
  //         setLinkedindata(results.data.results.data)

  //       } else {
  //         setLoader(false)
  //         toast.error(results.essage, {
  //           position: toast.POSITION.TOP_CENTER,
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       setLoader(false)
  //       toast.error(error.essage, {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //     });
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
    JobsApi();
    // JobsLinkedin(`${BaseUrl.baseUrl}indeed_za/retrieve_data/?page=1`);
  
    const locationState = location.state;
  
    if (locationState != null) {
      setlocations(locationState.filter);
    }
  }, [location.state]);


  const handleDownloadClick = (pdfLink) => {
    const link = document.createElement('a');
    console.log("pdfLink", link)
    link.href = pdfLink;
    // link.target = '_blank';  // Open in a new tab
    link.download = 'downloaded_file.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const handleCategoryClick = (category) => {
    // Set the selected category to filter jobs
    setSelectedCategory(category);
    setInitialRender(false);
  };

  // const toggleDescription = (index) => {
  //   setExpandedDescriptions((prevState) => ({
  //     ...prevState,
  //     [index]: !prevState[index],
  //   }));
  // };


  
  // const handleNextPage = () => {
  //   window.scrollTo(0, 0);
  //   if (next) {
  //     console.log("button next",next)
  //     JobsLinkedin(next);
  //   }
  // };

  // const handlePrevPage = () => {
  //   window.scrollTo(0, 0);

  //   if (prev) {
  //     JobsLinkedin(prev);
  //   }
  // };



  return (
    <div>
      {loader ? <Loader /> : null}

      <Header />
      <div class="main-content">
        <div class="page-content">
          <section className="page-title-box">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="text-center text-white">
                    <h3 className="mb-4">Job List </h3>
                    <div className="page-next">
                      <nav className="d-inline-block" aria-label="breadcrumb text-center">
                        <ol className="breadcrumb justify-content-center">
                          
                          <li className="breadcrumb-item">
                            <Link to="">Pages</Link>
                          </li>
                          <li className="breadcrumb-item active" aria-current="page">
                            {" "}
                            Job List {" "}
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
                {/*end col*/}
              </div>
              {/*end row*/}
            </div>
            {/*end container*/}
          </section>

          <div className="position-relative" style={{ zIndex: 1 }}>
            <div className="shape">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 250">
                <path
                  fill="#FFFFFF"
                  fillOpacity={1}
                  d="M0,192L120,202.7C240,213,480,235,720,234.7C960,235,1200,213,1320,202.7L1440,192L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                />
              </svg>
            </div>
          </div>


          <section className="section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="me-lg-5">

                    <section className="section bg-light">
                      <div className="container">
                        <div className="row justify-content-center">
                          {/* <div className="col-lg-6">
                            <div className="section-title text-center mb-4 pb-2">
                              <h4 className="title">Jobs Categories</h4>
                              <p className="text-muted mb-1">
                                Post a job to tell us about your project. We'll quickly match you
                                with the right freelancers.
                              </p>
                            </div>
                          </div> */}
                          {/*end col*/}
                        </div>
                        {/*end row*/}
                      

                        {/*end row*/}
                        {
                          locations === "govt" ?

                          <>
                        
                          <div className="row justify-content-center">
                          {category?.map((cat, index) => {
                            return (
                              <>

                                <div className="col-lg-3">
                                  <div className='job-list-menu nav nav-pills nav-justified flex-column flex-sm-row mb-4' id="pills-tab"
                                    role="tablist">
                                    <button
                                      className={`nav-link ${selectedCategory === cat ? 'active' : ''}`}
                                      id="recent-jobs-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#recent-jobs"
                                      type="button"
                                      role="tab"
                                      aria-controls="recent-jobs"
                                      aria-selected="true"
                                      onClick={() => handleCategoryClick(cat)}
                                    >
                                      {cat}
                                    </button>
                                  </div>
                                  {/* </li> */}
                                </div>
                              </>
                            )
                          })}

                          <div className="col-lg-3">
                            <div className='job-list-menu nav nav-pills nav-justified flex-column flex-sm-row mb-4' id="pills-tab"
                              role="tablist">
                              <button
                                className="nav-link active"
                                id="recent-jobs-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#recent-jobs"
                                type="button"
                                role="tab"
                                aria-controls="recent-jobs"
                                aria-selected="true"
                                onClick={() => { setInitialRender(true); setSelectedCategory(null) }}
                              >
                                View All Jobs
                              </button>


                              {/*end col*/}
                            </div>
                          </div>

                        </div>



                            <div className="row">
                              <div className="col-lg-12">
                                <div className="tab-content" id="pills-tabContent">
                                  {jobs?.map((job) => {
                                    return (

                                      <div
                                        key={job.id}
                                        className={`tab-pane fade ${initialRender || job.title.includes(selectedCategory) ? 'show active' : ''}`}
                                        id={`recent-jobs-${job.title.replace(/\s/g, '-')}`}
                                        role="tabpanel"
                                        aria-labelledby={`recent-jobs-tab-${job.title.replace(/\s/g, '-')}`}
                                      >
                                        <div className="job-box card mt-4">
                                          <div className="bookmark-label text-center">
                                            <Link
                                              to=""
                                              className="text-white align-middle"
                                            >
                                              <i className="mdi" >
                                                <FaStar />
                                              </i>
                                            </Link>
                                          </div>
                                          <div className="p-4">
                                            <div className="row align-items-center">
                                              <div className="col-md-4">
                                                <div className="text-center mb-4 mb-md-0">
                                                  <Link to="">
                                                    <img
                                                      src={jobicon}
                                                      alt=""
                                                      className="img-fluid rounded-3"
                                                      width={70}
                                                    />
                                                  </Link>
                                                </div>
                                              </div>
                                              {/*end col*/}
                                              <div className="col-md-4">
                                                <div className="mb-2 mb-md-0">
                                                  <h5 className="fs-18 mb-1">
                                                    <Link to="" className="text-dark">
                                                      {job?.title}
                                                    </Link>
                                                  </h5>
                                                  <p className="text-muted fs-14 mb-0">
                                                    {job?.adver}
                                                  </p>
                                                </div>
                                              </div>

                                              {/*job type*/}
                                              <div className="col-md-4">
                                                {/* Your other JSX code */}

                                                <div className="text-start text-md-end">
                                                  <button
                                                    disabled={job.pdf_link === ""}
                                                    onClick={() => handleDownloadClick(job.pdf_link)}
                                                    className="btn btn-primary">View Job </button>
                                                </div>
                                              </div>
                                              {/*end col*/}
                                            </div>
                                            {/*end row*/}
                                          </div>
                                          <div className="p-3 bg-light">
                                            <div className="row">
                                              <div className="col-md-4">
                                                <div>
                                                  <p className="text-muted mb-0">
                                                    <span className="text-dark"></span>{" "}
                                                    {job.list &&
                                                      job.list
                                                        .replace(/[[\]']+/g, '') // Remove square brackets and single quotes
                                                        .split(',')
                                                        .map((category) => category.trim()) // Remove extra spaces
                                                        .join(', ')}
                                                  </p>
                                                </div>
                                              </div>
                                              {/*end col*/}

                                              {/*end col*/}
                                              <div className="col-lg-2 col-md-3">
                                                <div className="text-start text-md-end">
                                                  {job.pdf_link === "" ?
                                                    null :

                                                    <Link
                                                      to=""

                                                      onClick={() => handleDownloadClick(job.pdf_link)}

                                                      className="primary-link"
                                                    >
                                                      Apply Now{" "}
                                                      <i className="" >
                                                        <FaAngleDoubleRight />
                                                      </i>
                                                    </Link>
                                                  }
                                                </div>
                                              </div>
                                              {/*end col*/}
                                            </div>
                                            {/*end row*/}
                                          </div>
                                        </div>
                                        {/*end job-box*/}

                                        {/*end job-box*/}

                                      </div>
                                    )
                                  })}


                                </div>
                              </div>
                              {/*end col*/}
                            </div>
                            </>

                            :

                            // locations === "indeed" ?

                              // <div className="row">
                              //   <h3 className='title'>Indeed</h3>

                              //   {linkedindata?.map((v, i) => {

                              //     const tempDiv = document.createElement('div');
                              //     tempDiv.innerHTML = v.job_description;
                              //     const textContent = tempDiv.textContent || tempDiv.innerText;

                              //     const limitedContent = textContent.substring(0, 200);
                              //     const shouldShowSeeMore = textContent.length > 200;
                              //     return (

                              //       <>
                              //         <div className="col-lg-4 col-md-6 mt-4">
                              //           <div className="card job-grid-box">
                              //             <div className="card-body p-4">

                              //               <div>
                              //                 <Link to="">
                              //                   <img
                              //                     src={jobicon}
                              //                     alt=""
                              //                     className="img-fluid rounded-3"
                              //                     width={70}
                              //                   />
                              //                 </Link>
                              //               </div>
                              //               <div className="mt-4">
                              //                 <Link to="" className="primary-link">
                              //                   <h5 className="fs-17 mb-1">{v.job_title}</h5>
                              //                 </Link>
                              //                 <p className="text-muted">{v.company_name}</p>
                              //                 <ul className="list-inline">
                              //                   <li className="list-inline-item">
                              //                     <span className="badge bg-soft-success fs-13 mt-1">
                              //                       {v.job_type}
                              //                     </span>
                              //                   </li>
                                               
                              //                 </ul>
                              //               </div>
                              //               <div className="job-grid-content mt-3">
                              //                 {expandedDescriptions[i] ? (
                              //                   <>
                              //                     <p className="text-muted">{textContent}</p>
                              //                     <button
                              //                       className="btn btn-link p-0"
                              //                       onClick={() => toggleDescription(i)}
                              //                     >
                              //                       See Less
                              //                     </button>
                              //                   </>
                              //                 ) : (
                              //                   <>
                              //                     <p className="text-muted">
                              //                       {limitedContent}
                              //                       {shouldShowSeeMore && (
                              //                         <span>
                              //                           {' '}
                              //                           <button
                              //                             className="btn btn-link p-0"
                              //                             onClick={() => toggleDescription(i)}
                              //                           >
                              //                             See More
                              //                           </button>
                              //                         </span>
                              //                       )}
                              //                     </p>
                              //                   </>
                              //                 )}
                              //                 <div className="d-flex align-items-center justify-content-between mt-4 border-top pt-3">
                                               
                              //                   <div className="text-end">
                              //                     <Link
                              //                       to={v.link}
                              //                       target="_blank"
                              //                       className="btn btn-sm btn-primary"
                              //                     >
                              //                       Apply Now <i className="uil uil-angle-right-b" />
                              //                     </Link>
                              //                   </div>
                              //                 </div>
                              //               </div>
                              //             </div>
                                         
                              //           </div>
                                       
                              //         </div>
                              //       </>
                              //     )

                              //   })}




                              //   {/*end col*/}
                              // </div>
                              // :
                              <>
                                  <div className="row justify-content-center">
                          {category?.map((cat, index) => {
                            return (
                              <>

                                <div className="col-lg-3">
                                  <div className='job-list-menu nav nav-pills nav-justified flex-column flex-sm-row mb-4' id="pills-tab"
                                    role="tablist">
                                    <button
                                      className={`nav-link ${selectedCategory === cat ? 'active' : ''}`}
                                      id="recent-jobs-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#recent-jobs"
                                      type="button"
                                      role="tab"
                                      aria-controls="recent-jobs"
                                      aria-selected="true"
                                      onClick={() => handleCategoryClick(cat)}
                                    >
                                      {cat}
                                    </button>
                                  </div>
                                  {/* </li> */}
                                </div>
                              </>
                            )
                          })}

                          <div className="col-lg-3">
                            <div className='job-list-menu nav nav-pills nav-justified flex-column flex-sm-row mb-4' id="pills-tab"
                              role="tablist">
                              <button
                                className="nav-link active"
                                id="recent-jobs-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#recent-jobs"
                                type="button"
                                role="tab"
                                aria-controls="recent-jobs"
                                aria-selected="true"
                                onClick={() => { setInitialRender(true); setSelectedCategory(null) }}
                              >
                                View All Jobs
                              </button>


                              {/*end col*/}
                            </div>
                          </div>

                        </div>

                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="tab-content" id="pills-tabContent">
                                      {jobs?.map((job) => {
                                        return (

                                          <div
                                            key={job.id}
                                            className={`tab-pane fade ${initialRender || job.title.includes(selectedCategory) ? 'show active' : ''}`}
                                            id={`recent-jobs-${job.title.replace(/\s/g, '-')}`}
                                            role="tabpanel"
                                            aria-labelledby={`recent-jobs-tab-${job.title.replace(/\s/g, '-')}`}
                                          >
                                            <div className="job-box card mt-4">
                                              <div className="bookmark-label text-center">
                                                <Link
                                                  to=""
                                                  className="text-white align-middle"
                                                >
                                                  <i className="mdi" >
                                                    <FaStar />
                                                  </i>
                                                </Link>
                                              </div>
                                              <div className="p-4">
                                                <div className="row align-items-center">
                                                  <div className="col-md-4">
                                                    <div className="text-center mb-4 mb-md-0">
                                                      <Link to="">
                                                        <img
                                                          src={jobicon}
                                                          alt=""
                                                          className="img-fluid rounded-3"
                                                          width={70}
                                                        />
                                                      </Link>
                                                    </div>
                                                  </div>
                                                  {/*end col*/}
                                                  <div className="col-md-4">
                                                    <div className="mb-2 mb-md-0">
                                                      <h5 className="fs-18 mb-1">
                                                        <Link to="" className="text-dark">
                                                          {job?.title}
                                                        </Link>
                                                      </h5>
                                                      <p className="text-muted fs-14 mb-0">
                                                        {job?.adver}
                                                      </p>
                                                    </div>
                                                  </div>

                                                  {/*job type*/}
                                                  <div className="col-md-4">
                                                    {/* Your other JSX code */}

                                                    <div className="text-start text-md-end">
                                                      <button
                                                        disabled={job.pdf_link === ""}
                                                        onClick={() => handleDownloadClick(job.pdf_link)}
                                                        className="btn btn-primary">View Job </button>
                                                    </div>
                                                  </div>
                                                  {/*end col*/}
                                                </div>
                                                {/*end row*/}
                                              </div>
                                              <div className="p-3 bg-light">
                                                <div className="row">
                                                  <div className="col-md-4">
                                                    <div>
                                                      <p className="text-muted mb-0">
                                                        <span className="text-dark"></span>{" "}
                                                        {job.list &&
                                                          job.list
                                                            .replace(/[[\]']+/g, '') // Remove square brackets and single quotes
                                                            .split(',')
                                                            .map((category) => category.trim()) // Remove extra spaces
                                                            .join(', ')}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  {/*end col*/}

                                                  {/*end col*/}
                                                  <div className="col-lg-2 col-md-3">
                                                    <div className="text-start text-md-end">
                                                      {job.pdf_link === "" ?
                                                        null :

                                                        <Link
                                                          to=""

                                                          onClick={() => handleDownloadClick(job.pdf_link)}

                                                          className="primary-link"
                                                        >
                                                          Apply Now{" "}
                                                          <i className="" >
                                                            <FaAngleDoubleRight />
                                                          </i>
                                                        </Link>
                                                      }
                                                    </div>
                                                  </div>
                                                  {/*end col*/}
                                                </div>
                                                {/*end row*/}
                                              </div>
                                            </div>
                                            {/*end job-box*/}

                                            {/*end job-box*/}

                                          </div>
                                        )
                                      })}


                                    </div>
                                  </div>
                                  {/*end col*/}
                                </div>

                                {/* <div className="row">
                                  <h3 className='title'>Indeed</h3>

                                  {linkedindata?.map((v, i) => {

                                    const tempDiv = document.createElement('div');
                                    tempDiv.innerHTML = v.job_description;
                                    const textContent = tempDiv.textContent || tempDiv.innerText;

                                    const limitedContent = textContent.substring(0, 200);
                                    const shouldShowSeeMore = textContent.length > 200;
                                    return (

                                      <>
                                        <div className="col-lg-4 col-md-6 mt-4">
                                          <div className="card job-grid-box">
                                            <div className="card-body p-4">

                                              <div>
                                                <Link to="">
                                                  <img
                                                    src={jobicon}
                                                    alt=""
                                                    className="img-fluid rounded-3"
                                                    width={70}
                                                  />
                                                </Link>
                                              </div>
                                              <div className="mt-4">
                                                <Link to={`/description/${encodeURIComponent(JSON.stringify(v))}`} className="primary-link">
                                                  <h5 className="fs-17 mb-1">{v.job_title}</h5>
                                                </Link>
                                                <p className="text-muted">{v.company_name}</p>
                                                <ul className="list-inline">
                                                  <li className="list-inline-item">
                                                    <span className="badge bg-soft-success fs-13 mt-1">
                                                      {v.job_type}
                                                    </span>
                                                  </li>
                                           </ul>
                                              </div>
                                              <div className="job-grid-content mt-3">
                                                {expandedDescriptions[i] ? (
                                                  <>
                                                    <p className="text-muted">{textContent}</p>
                                                    <button
                                                      className="btn btn-link p-0"
                                                      onClick={() => toggleDescription(i)}
                                                    >
                                                      See Less
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <p className="text-muted">
                                                      {limitedContent}
                                                      {shouldShowSeeMore && (
                                                        <span>
                                                          {' '}
                                                          <button
                                                            className="btn btn-link p-0"
                                                            onClick={() => toggleDescription(i)}
                                                          >
                                                            See More
                                                          </button>
                                                        </span>
                                                      )}
                                                    </p>
                                                  </>
                                                )}
                                                <div className="d-flex align-items-center justify-content-between mt-4 border-top pt-3">
                                                
                                                  <div className="text-end">
                                                    <Link
                                                      to={v.link}
                                                      target="_blank"
                                                      className="btn btn-sm btn-primary"
                                                    >
                                                      Apply Now <i className="uil uil-angle-right-b" />
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          
                                          </div>
                                         
                                        </div>
                                      </>
                                    )

                                  })}




                                
                                </div> */}
                              </>

                        }


                      
                      </div>
                      {/*end container*/}
                    </section>
                    {/* END JOB-LIST */}




                    {/* end row */}
                  </div>
                </div>

              </div>
            </div>
          </section>
          {/* {   locations !== "govt" ?
           <div className="row">
           <div className="col-lg-12 mt-3 mb-3">
             <nav aria-label="Page navigation example">
               <ul className="pagination job-pagination mb-0 justify-content-center">
                 <li className={`page-item ${prev === null ? '' : 'active'}`} >
                   <Link className="page-link" to="" onClick={() => handlePrevPage(prev)} disabled={prev === null}>
                     <i className="mdi mdi-chevron-double-left fs-15 active" />
                   </Link>
                 </li>

                 <li className={`page-item ${next === null ? '' : 'active'}`}>
                   <Link className="page-link" to="" onClick={() => handleNextPage(next)} disabled={next === null}>
                     <i className="mdi mdi-chevron-double-right fs-15 active" />
                   </Link>
                 </li>
               </ul>
             </nav>
           </div>
        
         </div>
         :""
          
        } */}
         


        </div>
      </div>
      <Footer />
    </div>

  )
}
