import React, { useEffect } from 'react'
import { useState } from 'react';
import { FaBriefcase } from "react-icons/fa";
import { FaStar, FaAngleDoubleRight, } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { BaseUrl } from '../baseurl/BaseUrl';
import { toast } from "react-toastify";
import axios from 'axios';
import jobicon from "../images/jobicon.png"
import Loader from './Loader';


export default function Home() {

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  const [category, setCategory] = useState([])
  const [jobs, setJobs] = useState([])
  // const [linkedindata, setLinkedindata] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  // const [expandedDescriptions, setExpandedDescriptions] = useState({});

  // const Token = localStorage.getItem("Token")

  console.log("jobs", jobs)


  useEffect(() => {
    window.scrollTo(0, 0);
    JobsApi();
    // JobsLinkedin()

  }, [])



  // Govt 
  const JobsApi = () => {
    setLoader(true)

    var requestOptions = {
      method: "get",
      redirect: "follow",
    };
    axios(`${BaseUrl.baseUrl}gov_za/retrieve_data`, requestOptions).then((result) => {
      if (result?.data?.status) {
        setLoader(false)
        const uniqueCategories = Array.from(new Set(result?.data?.category));
        setCategory(uniqueCategories);
        const uniqueJobs = Array.from(new Set(result?.data?.data.map(job => job.title)))
          .map(title => result?.data?.data.find(job => job.title === title));
        setJobs(uniqueJobs);
        // setFilteredJobs(uniqueJobs); // Initialize filtered jobs with all jobs


      } else {
        setLoader(false)
        toast.error(result.essage, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    })
      .catch((error) => {
        setLoader(false)
        toast.error(error.essage, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  // Linkedin 
  // const JobsLinkedin = () => {
  //   setLoader(true)

  //   var requestOptions = {
  //     method: "get",
  //     redirect: "follow",
  //   };
  //   axios(`${BaseUrl.baseUrl}indeed_za/retrieve_data/?page=1`, requestOptions).then((results) => {
  //     if (results.status === 200) {
  //       setLoader(false)
  //       setLinkedindata(results.data.results.data)

  //     } else {
  //       setLoader(false)
  //       toast.error(results.essage, {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //     }
  //   })
  //     .catch((error) => {
  //       setLoader(false)
  //       toast.error(error.essage, {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //     });
  // };




  const handleDownloadClick = (pdfLink) => {
    console.log("pdf link", pdfLink)
    const link = document.createElement('a');
    link.href = pdfLink;
    // link.target = '_blank';  // Open in a new tab
    link.download = 'downloaded_file.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const handleCategoryClick = (category) => {
    // Set the selected category to filter jobs
    setSelectedCategory(category);
    setInitialRender(false);
  };

  // const toggleDescription = (index) => {
  //   setExpandedDescriptions((prevState) => ({
  //     ...prevState,
  //     [index]: !prevState[index],
  //   }));
  // };



  const handleViewMoreClick = (jobname) => {
    // Example: Passing a filter parameter
    // Navigate to the "/jobs" page with the filter parameter
    // navigate("/jobs");
    navigate('/jobs', { state: { filter: jobname } });
    // console.log("jobname",jobname)
  };


  // Function to shuffle an array in-place
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Shuffle the linkedindata array
  const shuffledData = shuffleArray(jobs);

  // Display the first 5 elements
  const randomJobTitles = shuffledData.slice(0, 5);

  return (
    <div>
      {loader ? <Loader /> : null}

      <div class="main-content">

        <div class="page-content">
          <>
            {/* START HOME */}
            <section className="bg-home2" id="home">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-7">
                    <div className="mb-4 pb-3 me-lg-5">
                      {/* <h6 className="sub-title" style={{color:"rgb(255, 125, 2)"}}>We have 150,000+ live jobs</h6> */}
                      <h1 className=" fw-semibold mb-3 text-white">
                        Leungo Resources - Your Job-Sharing Hub!
                        {/* <span className=" fw-bold" style={{color:"#6440d6"}}>Leungo Resources</span> */}
                      </h1>
                      <p className="lead text-white mb-0">
                        Discover your dream job effortlessly with Leungo, where we make job sharing simple.

                      </p>
                    </div>
                    <div className="mb-2 me-lg-5">
                      <h4 className="sub-title fw-semibold" style={{ color: "rgb(255, 125, 2)" }}>Top 5 Jobs Today </h4>

                      <p className="lead text-white mb-0">
                        Explore, apply, and cultivate career success - it's all here at Leungo!

                      </p>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <ul class=" list-inline mb-0  mt-4 mt-lg-3 about-list list-unstyled  mb-4 pb-2">
                          {randomJobTitles.map((v, i) => (
                            <li key={i} className=" sub-title fw-semibold text-white">
                              <Link className='text-white' to={v.pdf_link}>{v.title},</Link>
                            </li>
                          ))}

                        </ul>

                      </div>

                    </div>

                    <form action="#">
                      <div className="registration-form">
                        <div className="row g-0">
                          <div className="col-md-4">
                            <div className="filter-search-form filter-border mt-3 mt-md-0">

                              <i className="uil"> <FaBriefcase /></i>

                              <input
                                type="search"
                                id="job-title"
                                className="form-control filter-input-box"
                                placeholder="Job, Company name..."
                              // onChange={(e) => setSearchTerm(e.target.value)}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className="col-md-4">
                            <div className="filter-search-form mt-3 mt-md-0">
                              {/* <i className='uil'>  <FaMapMarkerAlt /></i> */}
                              <input
                                className="form-control filter-input-box"
                                placeholder="South Africa"
                                disabled
                              />

                            </div>
                          </div>
                          {/*end col*/}
                          <div className="col-md-4">
                            <div className="mt-3 mt-md-0 h-100">
                              <button
                                className="btn btn-primary submit-btn w-100 h-100"
                                type="button"
                              // onClick={handleSearch}
                              >
                                <i className="uil uil-search me-1" /> Find Job
                              </button>
                            </div>
                          </div>
                          {/*end col*/}
                        </div>
                        {/*end row*/}
                      </div>
                    </form>
                  </div>
                  {/*end col*/}
                  {/* <div className="col-lg-5">
                    <div className="mt-5 mt-md-0">
                      <img
                        src="assets/images/process-02.png"
                        alt=""
                        className="home-img"
                      />
                    </div>
                  </div> */}
                  {/*end col*/}
                </div>
                {/*end row*/}
              </div>
              {/*end container*/}
            </section>
            {/* End Home */}
            {/* START SHAPE */}
            <div className="position-relative">
              <div className="shape">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width={1440}
                  height={150}
                  preserveAspectRatio="none"
                  viewBox="0 0 1440 220"
                >
                  <g mask='url("#SvgjsMask1004")' fill="none">
                    <path
                      d="M 0,213 C 288,186.4 1152,106.6 1440,80L1440 250L0 250z"
                      fill="rgba(255, 255, 255, 1)"
                    />
                  </g>
                  <defs>
                    <mask id="SvgjsMask1004">
                      <rect width={1440} height={250} fill="#ffffff" />
                    </mask>
                  </defs>
                </svg>
              </div>
            </div>
            {/* END SHAPE */}
          </>

          <>

            <section className="section bg-light">
              <div className="container">
                <div className="row justify-content-center">
                  {/* <div className="col-lg-6">
                    <div className="section-title text-center mb-4 pb-2">
                      <h4 className="title">Jobs Categories</h4>
                      <p className="text-muted mb-1">
                        Post a job to tell us about your project. We'll quickly match you
                        with the right freelancers.
                      </p>
                    </div>
                  </div> */}
                  {/*end col*/}
                </div>
                {/*end row*/}
                <div className="row justify-content-center">
                  {category?.map((cat, index) => {
                    return (
                      <>

                        <div className="col-lg-3">
                          <div className='job-list-menu nav nav-pills nav-justified flex-column flex-sm-row mb-4' id="pills-tab"
                            role="tablist">
                            <button
                              className={`nav-link ${selectedCategory === cat ? 'active' : ''}`}
                              id="recent-jobs-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#recent-jobs"
                              type="button"
                              role="tab"
                              aria-controls="recent-jobs"
                              aria-selected="true"
                              onClick={() => handleCategoryClick(cat)}
                            >
                              {cat}
                            </button>
                          </div>
                          {/* </li> */}
                        </div>
                      </>
                    )
                  })}

                  <div className="col-lg-3">
                    <div className='job-list-menu nav nav-pills nav-justified flex-column flex-sm-row mb-4' id="pills-tab"
                      role="tablist">
                      <button
                        className="nav-link active"
                        id="recent-jobs-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#recent-jobs"
                        type="button"
                        role="tab"
                        aria-controls="recent-jobs"
                        aria-selected="true"
                        onClick={() => { setInitialRender(true); setSelectedCategory(null) }}
                      >
                        View All Jobs
                      </button>


                      {/*end col*/}
                    </div>
                  </div>

                </div>
                {/* GOVERNMENT   */}
                {/*end row*/}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="tab-content" id="pills-tabContent">
                      {/* <h4 className='title'>Government</h4> */}

                      {jobs?.map((job) => {
                        return (

                          <div
                            key={job.id}
                            className={`tab-pane fade ${initialRender || job.title.includes(selectedCategory) ? 'show active' : ''}`}
                            id={`recent-jobs-${job.title.replace(/\s/g, '-')}`}
                            role="tabpanel"
                            aria-labelledby={`recent-jobs-tab-${job.title.replace(/\s/g, '-')}`}
                          >
                            <div className="job-box card mt-4">
                              <div className="bookmark-label text-center">
                                <Link
                                  to=""
                                  className="text-white align-middle"
                                >
                                  <i className="mdi" >
                                    <FaStar />
                                  </i>
                                </Link>
                              </div>
                              <div className="p-4">
                                <div className="row align-items-center">
                                  <div className="col-md-4">
                                    <div className="text-center mb-4 mb-md-0">
                                      <Link to="">
                                        <img
                                          src={jobicon}
                                          alt=""
                                          className="img-fluid rounded-3"
                                          width={70}
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                  {/*end col*/}
                                  <div className="col-md-4">
                                    <div className="mb-2 mb-md-0">
                                      <h5 className="fs-18 mb-1">
                                        <Link to="" className="text-dark">
                                          {job?.title}
                                        </Link>
                                      </h5>
                                      <p className="text-muted fs-14 mb-0">
                                        {job?.adver}
                                      </p>
                                    </div>
                                  </div>

                                  {/*job type*/}
                                  <div className="col-md-4">
                                    {/* Your other JSX code */}

                                    <div className="text-start text-md-end">
                                      <button
                                        disabled={job.pdf_link === ""}
                                        onClick={() => handleDownloadClick(job.pdf_link)}
                                        className="btn btn-primary">View Job </button>
                                    </div>
                                  </div>
                                  {/*end col*/}
                                </div>
                                {/*end row*/}
                              </div>
                              <div className="p-3 bg-light">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div>
                                      <p className="text-muted mb-0">
                                        <span className="text-dark"></span>{" "}
                                        {job.list &&
                                          job.list
                                            .replace(/[[\]']+/g, '') // Remove square brackets and single quotes
                                            .split(',')
                                            .map((category) => category.trim()) // Remove extra spaces
                                            .join(', ')}
                                      </p>
                                    </div>
                                  </div>
                                  {/*end col*/}

                                  {/*end col*/}
                                  <div className="col-lg-2 col-md-3">
                                    <div className="text-start text-md-end">
                                      {job.pdf_link === "" ?
                                        null :

                                        <Link
                                          to=""

                                          onClick={() => handleDownloadClick(job.pdf_link)}

                                          className="primary-link"
                                        >
                                          Apply Now{" "}
                                          <i className="" >
                                            <FaAngleDoubleRight />
                                          </i>
                                        </Link>
                                      }
                                    </div>
                                  </div>
                                  {/*end col*/}
                                </div>
                                {/*end row*/}
                              </div>
                            </div>
                            {/*end job-box*/}

                            {/*end job-box*/}

                          </div>
                        )
                      })}
                      {/* <div className="text-center mt-4 pt-2">
                        <Link to="" className="btn btn-primary">
                          View More <i className="uil uil-arrow-right" />
                        </Link>
                      </div> */}

                    </div>
                  </div>

                  <div className="mt-4 pt-2 d-flex justify-content-center">
                    <button
                      // to="#"
                      className="btn btn-primary btn-hover"
                      onClick={() => handleViewMoreClick("govt")}


                    >
                      View More <i className="uil uil-rocket align-middle ms-1" />
                    </button>
                  </div>

                  {/*end col*/}
                </div>


                {/* lINKEDIN  */}
                {/*end row*/}
                {/* <>
                  <div className="row">
                    <h3 className='title'>Indeed</h3>

                    {linkedindata?.map((v, i) => {

                      const tempDiv = document.createElement('div');
                      tempDiv.innerHTML = v.job_description;
                      const textContent = tempDiv.textContent || tempDiv.innerText;

                      const limitedContent = textContent.substring(0, 200);
                      const shouldShowSeeMore = textContent.length > 200;
                      return (

                        <>
                          <div className="col-lg-4 col-md-6 mt-4">
                            <div className="card job-grid-box">
                              <div className="card-body p-4">

                                <div>
                                  <Link to="">
                                    <img
                                      src={jobicon}
                                      alt=""
                                      className="img-fluid rounded-3"
                                      width={70}
                                    />
                                  </Link>
                                </div>
                                <div className="mt-4">
                                  <Link to={`/description/${encodeURIComponent(JSON.stringify(v))}`} className="primary-link">
                                    <h5 className="fs-17 mb-1">{v.job_title}</h5>
                                  </Link>
                                  <p className="text-muted">{v.company_name}</p>
                                  <ul className="list-inline">
                                    <li className="list-inline-item">
                                      <span className="badge bg-soft-success fs-13 mt-1">
                                        {v.job_type}
                                      </span>
                                    </li>
                                   
                                  </ul>
                                </div>
                                <div className="job-grid-content mt-3">
                                  {expandedDescriptions[i] ? (
                                    <>
                                      <p className="text-muted">{textContent}</p>
                                      <button
                                        className="btn btn-link p-0"
                                        onClick={() => toggleDescription(i)}
                                      >
                                        See Less
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <p className="text-muted">
                                        {limitedContent}
                                        {shouldShowSeeMore && (
                                          <span>
                                            {' '}
                                            <button
                                              className="btn btn-link p-0"
                                              onClick={() => toggleDescription(i)}
                                            >
                                              See More
                                            </button>
                                          </span>
                                        )}
                                      </p>
                                    </>
                                  )}
                                  <div className="d-flex align-items-center justify-content-between mt-4 border-top pt-3">
                                
                                    <div className="text-end">
                                      <Link
                                        to={v.link}
                                        target="_blank"
                                        className="btn btn-sm btn-primary"
                                      >
                                        Apply Now <i className="uil uil-angle-right-b" />
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            
                            </div>
                          
                          </div>
                        </>
                      )

                    })}


                    <div className="mt-4 pt-2 d-flex justify-content-center">
                      <button

                        className="btn btn-primary btn-hover"
                        onClick={() => handleViewMoreClick("indeed")}

                      >
                        View More <i className="uil uil-rocket align-middle ms-1" />
                      </button>
                    </div>

                  
                  </div>
                
                </> */}



                {/*end row*/}
              </div>
              {/*end container*/}
            </section>
            {/* END JOB-LIST */}
          </>
       




        </div>
      </div>
    </div>

  )
}
