import React from 'react'
import {  FaFacebookF, FaGoogle, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Logo from "../images/Leungo-03.png"

export default function Footer() {
  return (
    <div>
      <>
        {/* START FOOTER */}
        <section className="bg-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="footer-item mt-4 mt-lg-0 me-lg-5">
                  {/* <h4 className="text-white mb-4">LEUNGO</h4> */}
                  <Link className="navbar-brand text-dark fw-bold me-auto" to="/">
                    <img
                      src={Logo}
                      height={40}
                      alt=""
                      className="logo-dark"
                    />
                    <img
                      src={Logo}
                      height={40}
                      alt=""
                      className="logo-light"
                    />
                  </Link>
                
                  <p className="text-white mt-3">Follow Us on:</p>
                  <ul className="footer-social-menu list-inline mb-0">
                    <li className="list-inline-item">
                      <Link to="#">
                        <i className="" >
                          <FaFacebookF /></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#">
                        <i className="" >
                          <FaLinkedinIn />
                        </i>

                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#">

                        <i className="" >
                          <FaGoogle />
                        </i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#">

                        <i className="" >
                          <FaTwitter />
                        </i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/*end col*/}
              <div className="col-lg-2 col-6">
                <div className="footer-item mt-4 mt-lg-0">
                  <p className="fs-16 text-white mb-4">Home</p>
                  {/* <ul className="list-unstyled footer-list mb-0">
                    <li>
                      <Link to="">
                        <i className="" >
                          <FaAngleRight /></i> About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <i className="" >
                          <FaAngleRight /></i> Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <i className="" >
                          <FaAngleRight /></i> Services
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <i className="" >
                          <FaAngleRight /></i> Blog
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <i className="" >
                          <FaAngleRight /></i> Team
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <i className="" >
                          <FaAngleRight /></i> Pricing
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </div>
              {/*end col*/}
              <div className="col-lg-2 col-6">
                <div className="footer-item mt-4 mt-lg-0">
                  <p className="fs-16 text-white mb-4">For Jobs</p>
                  {/* <ul className="list-unstyled footer-list mb-0">
                    <li>
                      <Link to="">
                        <i className="" >
                          <FaAngleRight /></i> Browser Categories
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <i className="" >
                          <FaAngleRight /></i> Browser Jobs
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <i className="" >
                          <FaAngleRight /></i> Job Details
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <i className="" >
                          <FaAngleRight /></i> Bookmark Jobs
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </div>
              {/*end col*/}
              <div className="col-lg-2 col-6">
                <div className="footer-item mt-4 mt-lg-0">
                  <p className="text-white fs-16 mb-4">Career Resources</p>
                  {/* <ul className="list-unstyled footer-list mb-0">
                    <li>
                      <Link to="">
                        <i className="" >
                          <FaAngleRight /></i> Candidate List
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <i className="" >
                          <FaAngleRight /></i> Candidate Grid
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <i className="" >
                          <FaAngleRight /></i> Candidate Details
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </div>
              {/*end col*/}
              <div className="col-lg-2 col-6">
                <div className="footer-item mt-4 mt-lg-0">
                  <p className="fs-16 text-white mb-4">Contact</p>
                  {/* <ul className="list-unstyled footer-list mb-0">
                    <li>
                      <Link to="">
                        <i className="" >
                          <FaAngleRight /></i> Help Center
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <i className="" >
                          <FaAngleRight /></i> FAQ'S
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <i className="" >
                          <FaAngleRight /></i> Privacy Policy
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
          {/*end container*/}
        </section>
        {/* END FOOTER */}
        {/* START FOOTER-ALT */}
        <div className="footer-alt">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p className="text-white-50 text-center mb-0">
                  Leungo Resources - Made By Hnh Tech Solutions{" "}
                  <Link
                    to=""
                    target="_blank"
                    className="text-reset text-decoration-underline"
                  >

                  </Link>
                </p>
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
          {/*end container*/}
        </div>
        {/* END FOOTER */}
      </>

    </div>
  )
}
