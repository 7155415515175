import React from 'react'
import { useEffect } from 'react';
import Header from '../layout/Header'
import { Link } from 'react-router-dom'
import Footer from '../layout/Footer'
export default function Career() {

    useEffect(() => {
        window.scrollTo(0, 0);
        
        }, [])

    return (
        <div>
            <Header />
            <div className="main-content">
                <div className="page-content">
                    {/* Start home */}
                    <section className="page-title-box">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-6">
                                    <div className="text-center text-white">
                                        <h3 className="mb-4">Career Resources</h3>
                                        <div className="page-next">
                                            <nav
                                                className="d-inline-block"
                                                aria-label="breadcrumb text-center"
                                            >
                                                <ol className="breadcrumb justify-content-center">
                                                    <li className="breadcrumb-item">
                                                        <Link to="/">Home</Link>
                                                    </li>

                                                    <li className="breadcrumb-item active" aria-current="page">
                                                        Career Resources
                                                    </li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                {/*end col*/}
                            </div>
                            {/*end row*/}
                        </div>
                        {/*end container*/}
                    </section>
                    {/* end home */}
                    {/* START SHAPE */}
                    <div className="position-relative" style={{ zIndex: 1 }}>
                        <div className="shape">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 250">
                                <path
                                    fill="#FFFFFF"
                                    fillOpacity={1}
                                    d="M0,192L120,202.7C240,213,480,235,720,234.7C960,235,1200,213,1320,202.7L1440,192L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                                />
                            </svg>
                        </div>
                    </div>
                    {/* END SHAPE */}
                    {/* START PRIVACY-POLICY */}
                    <section className="section">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-6">
                                    <div className="section-title text-center mb-4 pb-2">
                                        <h4 className="title">Top 5 Sites for CV Templates</h4>

                                    </div>
                                </div>
                                {/*end col*/}
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <h5 className="mb-4">CareerJunction CV Builder
                                    </h5>
                                    <ul className="about-list list-unstyled text-muted mb-4 pb-2">
                                        <li>
                                            <Link to="https://www.careerjunction.co.za/blog/how-to-write-a-cv/">CareerJunction CV Builder</Link>   (Free, user-friendly builder with South African-specific templates)

                                        </li>

                                    </ul>
                                    <h5 className="mb-4">The CV Shop:</h5>
                                    <ul className="about-list list-unstyled text-muted mb-4 pb-2">
                                        <li><Link to="https://cvchamp.com/en/blog/resume-templates/salesperson-resume/">The CV Shop</Link> (Paid templates, but high quality and professionally designed)
                                        </li>

                                    </ul>
                                    <h5 className="mb-4">Department of Labour CV Template:</h5>
                                    <ul className="about-list list-unstyled text-muted mb-4 pb-2">
                                        <li>
                                            <Link to="https://www.labour.gov.za/">Department of Labour CV Template</Link> (Free, basic template by the Department of Labour)

                                        </li>

                                    </ul>

                                    <h5 className="mb-4">CareerGuide.co.za:</h5>
                                    <ul className="about-list list-unstyled text-muted mb-4 pb-2">
                                        <li>
                                            <Link to="https://careerguidesa.org/">CareerGuide.co.za </Link>(Free templates and CV writing tips)

                                        </li>

                                    </ul>

                                    <h5 className="mb-4">Monster.co.za CV Guide:</h5>
                                    <ul className="about-list list-unstyled text-muted mb-4 pb-2">
                                        <li>
                                            <Link to="https://www.monster.com/career-advice/resumes">Monster.co.za CV Guide</Link>   (Free templates and tips tailored to the South African job market)


                                        </li>

                                    </ul>


                                </div>
                                {/*end col*/}
                            </div>


                            <div className="row justify-content-center">
                                <div className="col-lg-6">
                                    <div className="section-title text-center mb-4 pb-2">
                                        <h4 className="title">Top 5 Links for CV Information and Resources
                                        </h4>

                                    </div>
                                </div>
                                {/*end col*/}
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <h5 className="mb-4">CareerJunction Career Advice:
                                    </h5>
                                    <ul className="about-list list-unstyled text-muted mb-4 pb-2">
                                        <li>
                                            <Link to="https://www.careerjunction.co.za/">CareerJunction Career Advice</Link> (Articles and tips on CV writing, interviews, and job search strategies)


                                        </li>

                                    </ul>
                                    <h5 className="mb-4">The Graduate Institute:</h5>
                                    <ul className="about-list list-unstyled text-muted mb-4 pb-2">
                                        <li><Link to="https://www.graduateinstitute.ch/master-phd-programmes">The Graduate Institute</Link> (Offers workshops and resources on CV writing and career development)
                                        </li>

                                    </ul>
                                    <h5 className="mb-4">Youth Employment Service (YES):</h5>
                                    <ul className="about-list list-unstyled text-muted mb-4 pb-2">
                                        <li>
                                            <Link to="https://www.yes4youth.co.za/for-youth">Youth Employment Service (YES)</Link> (Provides CV writing assistance and job search support)


                                        </li>

                                    </ul>

                                    <h5 className="mb-4">The Department of Labour Career Resources:
                                    </h5>
                                    <ul className="about-list list-unstyled text-muted mb-4 pb-2">
                                        <li>
                                            <Link to="https://www.labour.gov.za/">The Department of Labour Career Resources</Link>    (Links to resources and information on CV writing, job search, and career development)

                                        </li>

                                    </ul>

                                    <h5 className="mb-4">BusinessTech Career Guides:</h5>
                                    <ul className="about-list list-unstyled text-muted mb-4 pb-2">
                                        <li>
                                            <Link to="https://businesstech.co.za/news/">BusinessTech Career Guides</Link>     (Articles and guides on CV writing, job interviews, and career paths)


                                        </li>

                                    </ul>


                                </div>
                                {/*end col*/}
                            </div>


                            <div className="row justify-content-center">
                                <div className="col-lg-6">
                                    <div className="section-title text-center mb-4 pb-2">
                                        <h4 className="title">
                                            Preparation and Mindset Links for Job Seekers

                                        </h4>

                                    </div>
                                </div>
                                {/*end col*/}
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <h5 className="mb-4">Self-assessment and Goal Setting:
                                    </h5>
                                    <ul className="about-list list-unstyled text-muted mb-4 pb-2">
                                        <li>
                                            The YDP Self-Assessment Quiz: <Link to="https://www.search-institute.org/wp-content/uploads/2018/01/YAPS-Survey-User-Guide-1-2016.pdf">https://www.search-institute.org/wp-content/uploads/2018/01/YAPS-Survey-User-Guide-1-2016.pdf</Link>   - Helps identify skills, interests, and potential career paths.
                                        </li>

                                        <li>
                                            GOALcast:<Link to=" https://www.goalcast.com/"> https://www.goalcast.com/</Link>  - Inspiring video platform with talks and interviews on goal setting and achieving success.
                                        </li>

                                    </ul>
                                    <h5 className="mb-4">Motivational and Mindset Resources:</h5>
                                    <ul className="about-list list-unstyled text-muted mb-4 pb-2">
                                        <li>The South African Journal of Industrial Psychology:<Link to=" https://sajip.co.za/ "> https://sajip.co.za/ </Link> - Articles and research on job search motivation and career development.
                                        </li>
                                        <li>TEDx Talks:<Link to="https://www.ted.com/ ">https://www.ted.com/ </Link> - Search for talks on overcoming challenges, finding purpose, and building resilience.
                                        </li>
                                        <li>The Positivity Project:<Link to=" https://posproject.org/ "> https://posproject.org/ </Link>- Resources and programs on positive psychology and building a happier, more fulfilling life.
                                        </li>

                                    </ul>
                                    <h5 className="mb-4">Networking Guidance:</h5>
                                    <ul className="about-list list-unstyled text-muted mb-4 pb-2">
                                        <li>
                                            Linkedin: <Link to="https://www.linkedin.com/">https://www.linkedin.com/</Link>  - Essential for building professional connections and networking with potential employers.
                                        </li>
                                        <li>
                                            CareerJunction Events: <Link to=" https://www.careerjunction.co.za/account/signin "> https://www.careerjunction.co.za/account/signin </Link>- Attend networking events and career fairs to connect with industry professionals.
                                        </li>
                                        <li>
                                            Toastmasters International: <Link to=" https://www.toastmasters.org/ "> https://www.toastmasters.org/ </Link>- Improve your public speaking and communication skills, which can be valuable for networking.

                                        </li>

                                    </ul>

                                    <h5 className="mb-4">Additional Resources:
                                    </h5>
                                    <ul className="about-list list-unstyled text-muted mb-4 pb-2">
                                        <li>
                                            The Department of Labour Career Resources: <Link to="https://www.labour.gov.za/">https://www.labour.gov.za/</Link>  - Links to resources on career development, training, and support programs.
                                        </li>
                                        <li>
                                            The National Youth Development Agency (NYDA): <Link to="https://www.nyda.gov.za/ ">https://www.nyda.gov.za/ </Link> - Offers support and resources for young job seekers in South Africa.
                                        </li>
                                        <li>
                                            The Department of Labour Career Resources: <Link to="https://www.labour.gov.za/ ">https://www.labour.gov.za/ </Link> - Links to resources on career development, training, and support programs.
                                        </li>

                                    </ul>



                                    <h5 className="mb-4">Disclaimer:
                                    </h5>
                                    <ul className="about-list list-unstyled text-muted mb-4 pb-2">
                                        <li>
                                            <strong> Copyright & Usage:</strong> The resources we link to have their own copyright and usage terms (owned by their respective creators). Review these on each site before downloading.
                                        </li>
                                        <li>
                                            <strong>Accuracy & Reliability:</strong> We strive for reliable resources, but we can't guarantee external site accuracy. Cross-check with other sources.
                                        </li>
                                        <li>
                                            <strong>Responsibility Disclaimer:</strong> Leungo Resources Pty (Ltd) isn't responsible for external site content, accuracy, or opinions. We aren't affiliated and don't endorse their products or services.
                                        </li>

                                    </ul>



                                </div>
                                {/*end col*/}
                            </div>
                            {/*end row*/}
                        </div>
                        {/*end container*/}
                    </section>
                    {/* END PRIVACY-POLICY */}
                </div>



            </div>
            <Footer />
        </div>
    )
}
