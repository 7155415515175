import React from 'react'
import Jobdetail from '../components/jobdetail'
export default function Description() {
  return (
    <div>
      <Jobdetail/>
      
    </div>
  )
}
