import React from 'react'
import { Link } from 'react-router-dom';
import Logo from "../images/Leungo-01.png"
export default function Header() {
  // const Token = localStorage.getItem("Token")

 

  // const Logout = () => {
  //   localStorage.clear()
  // }
  return (
    <div>


      <nav className="navbar navbar-expand-lg fixed-top sticky" id="navbar" style={{ marginTop: "0" }}>
        <div className="container-fluid custom-container">
          <Link className="navbar-brand text-dark fw-bold me-auto" to="/">
            <img
              src={Logo}
              height={40}
              alt=""
              className="logo-dark"
            />
            <img
              src={Logo}
              height={40}
              alt=""
              className="logo-light"
            />
          </Link>
          <div>
            <button
              className="navbar-toggler me-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-label="Toggle navigation"
            >
              <i className="mdi mdi-menu" />
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav mx-auto navbar-center">
        
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/jobs" className="nav-link">
                  Jobs
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/careerresources" className="nav-link">
                  Career Resources
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link">
                  Contact
                </Link>
              </li>
              {/*end dropdown*/}

            </ul>
            {/*end navbar-nav*/}
          </div>
          {/* end navabar-collapse*/}
          {/* <ul className="header-menu list-inline d-flex align-items-center mb-0">
            {Token != null ?

              <li className="list-inline-item dropdown ">
                <Link
                  to="/"
                  onClick={Logout}
                  className=" nav-link btn btn-outline-primary" >

                  Logout
                </Link>
              </li>

              :
              <>

                <li className="list-inline-item dropdown ">
                  <Link
                    to="/signup"
                    className=" nav-link btn btn-outline-primary  ">

                    Sign Up
                  </Link>

                </li>
                <li className="list-inline-item dropdown ">
                  <Link
                    to="/"
                    className=" nav-link btn btn-outline-primary " >

                    Login
                  </Link>

                </li>
              </>
            }
          </ul> */}
        </div>
        {/*end container*/}
      </nav>

    </div>
  )
}
